//src/api/index.js
import request from "@/plugins/request";

/**
 * 用户信息
 */

//登录接口
export function login(obj) {
  return request({
    url: "/user/login",
    method: "POST",
    data: obj,
  });
}

//退出登录接口
export function loginOut(obj) {
  return request({
    url: "/user/logout",
    method: "POST",
    data: obj,
  });
}

//注册用户
export function userRegister(obj) {
  return request({
    url: "/user/register",
    method: "POST",
    data: obj,
  });
}

//查看用户信息
export function userInfo(obj) {
  return request({
    url: "/user/info",
    method: "POST",
    data: obj,
  });
}

//修改用户信息
export function userUpdate(obj) {
  return request({
    url: "/user/update",
    method: "POST",
    data: obj,
  });
}

//修改用户tel，phone
export function updateBaseInfo(obj) {
  return request({
    url: "/user/updateBaseInfo",
    method: "POST",
    data: obj,
  });
}

//禁用用户
export function userDisable(obj) {
  return request({
    url: "/user/disable",
    method: "POST",
    data: obj,
  });
}

//启用用户
export function userEnable(obj) {
  return request({
    url: "/user/enable",
    method: "POST",
    data: obj,
  });
}

//修改密码
export function changePassword(obj) {
  return request({
    url: "/user/changePassword",
    method: "POST",
    data: obj,
  });
}

//重置密码
export function resetPassword(obj) {
  return request({
    url: "/user/resetPassword",
    method: "POST",
    data: obj,
  });
}

//用户列表
export function userSearchList(obj) {
  return request({
    url: "/user/searchList",
    method: "POST",
    data: obj,
  });
}

//更换角色
export function userChangeRolet(obj) {
  return request({
    url: "/user/changeRolet",
    method: "POST",
    data: obj,
  });
}

/**
 * 角色
 */

//角色列表
export function roleList(obj) {
  return request({
    url: "/role/list",
    method: "POST",
    data: obj,
  });
}

//角色page
export function rolePage(obj) {
  return request({
    url: "/role/page",
    method: "POST",
    data: obj,
  });
}

//角色增加
export function roleCreate(obj) {
  return request({
    url: "/role/create",
    method: "POST",
    data: obj,
  });
}

//角色删除
export function roleRemove(obj) {
  return request({
    url: "/role/remove",
    method: "POST",
    data: obj,
  });
}

//角色更新
export function roleUpdate(obj) {
  return request({
    url: "/role/update",
    method: "POST",
    data: obj,
  });
}

//所有权限列表
export function authList(obj) {
  return request({
    url: "/menu/list/all",
    method: "POST",
    data: obj,
  });
}

//获取当前角色有的权限 (都返回，但是通过select字段区分是否被选中)
export function roleAuth(obj) {
  return request({
    url: "/menu/list/compare",
    method: "POST",
    data: obj,
  });
}

//当前用户有的权限
export function userAuth(obj = {}) {
  return request({
    url: "/menu/list/current",
    method: "POST",
    data: obj,
  });
}

/**
 * 公司
 */
//启用开放接口
export function openApiEnalbe(obj) {
  return request({
    url: "emsc/company/openApi/enable",
    method: "POST",
    data: obj,
  });
}
//禁用开放接口
export function openApiDisalbe(obj) {
  return request({
    url: "emsc/company/openApi/disable",
    method: "POST",
    data: obj,
  });
}
//公司统计
export function companyStatistic(obj) {
  return request({
    url: "/emsc/company/statistic",
    method: "POST",
    data: obj,
  });
}

export function companyStatisticPage(obj) {
  return request({
    url: "/emsc/company/statistic/page",
    method: "POST",
    data: obj,
  });
}

//公司列表
export function companyList(obj) {
  return request({
    url: "/emsc/company/list",
    method: "POST",
    data: obj,
  });
}

//公司列表（page）
export function companyPage(obj) {
  return request({
    url: "/emsc/company/page",
    method: "POST",
    data: obj,
  });
}

//公司启用
export function companyEnable(obj) {
  return request({
    url: "/emsc/company/enable",
    method: "POST",
    data: obj,
  });
}

//公司禁用
export function companyDisable(obj) {
  return request({
    url: "/emsc/company/disable",
    method: "POST",
    data: obj,
  });
}

//公司创建
export function companyCreate(obj) {
  return request({
    url: "/emsc/company/create",
    method: "POST",
    data: obj,
  });
}

//获取公司管理员
export function companyAdminInfo(obj) {
  return request({
    url: "/emsc/company/adminInfo",
    method: "POST",
    data: obj,
  });
}

//公司更新
export function companyUpdate(obj) {
  return request({
    url: "emsc/company/update",
    method: "POST",
    data: obj,
  });
}

/**
 * 公司用户
 */
//列表
export function companyUserPage(headers, obj) {
  return request({
    headers: headers,
    url: "/front/user/search/page",
    method: "POST",
    data: obj,
  });
}

//重置密码
export function companyUserReset(headers, obj) {
  return request({
    headers: headers,
    url: "/front/user/resetPassword",
    method: "POST",
    data: obj,
  });
}

/**
 * 站点
 */

//站点列表
export function powerStationList(headers, obj) {
  return request({
    headers: headers,
    url: "/powerStation/searchList",
    method: "POST",
    data: obj,
  });
}

//站点page
export function powerStationPage(headers, obj) {
  return request({
    headers: headers,
    url: "/powerStation/searchPage",
    method: "POST",
    data: obj,
  });
}

//站点显示
export function powerStationDisplay(headers, obj) {
  return request({
    headers: headers,
    url: "/powerStation/display",
    method: "POST",
    data: obj,
  });
}

//站点隐藏
export function powerStationHide(headers, obj) {
  return request({
    headers: headers,
    url: "/powerStation/hide",
    method: "POST",
    data: obj,
  });
}

//站点创建
export function powerStationCreate(headers, obj) {
  return request({
    headers: headers,
    url: "/powerStation/rpc/create",
    method: "POST",
    data: obj,
  });
}

//站点查询
export function powerStationSearch(headers, obj) {
  return request({
    headers: headers,
    url: "/powerStation/rpc/search",
    method: "POST",
    data: obj,
  });
}

//站点更新
export function powerStationUpdate(headers, obj) {
  return request({
    headers: headers,
    url: "/powerStation/rpc/update",
    method: "POST",
    data: obj,
  });
}

export function verifyCode(headers, obj) {
  return request({
    headers: headers,
    url: "/consistent/verifyCode",
    method: "POST",
    data: obj,
  });
}

//更新口令
export function powerStationCommand(headers, obj) {
  return request({
    headers: headers,
    url: "/powerStation/rpc/updateCommand",
    method: "POST",
    data: obj,
  });
}

// 电站预警-分页查询自动巡检
export function getInspectionPage(obj) {
  return request({
    url: "/emsc/inspection/page",
    method: "POST",
    data: obj,
  });
}

// 电站预警-更新当前记录的监控状态
export function updateInspectionRecord(obj) {
  return request({
    url: "/emsc/inspection/update",
    method: "POST",
    data: obj,
  });
}

// 维护通知-设置运维通知
export function bannerSet(obj) {
  return request({
    url: "/banner/set",
    method: "POST",
    data: obj,
  });
}
// 维护通知-获取运维通知
export function bannerGet(obj) {
  return request({
    url: "/banner/get",
    method: "GET",
    data: obj,
  });
}
