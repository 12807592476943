import Vue from "vue";
import axios from "axios";
import router from "@/router";

// 创建 axios 实例
const service = axios.create({
    baseURL: "/v1",
    timeout: 10 * 1000, // request timeout
});

// 多次401 防抖
function debounce401(fn) {
    let timer = null;
    return function () {
        clearTimeout(timer);
        timer = null;
        timer = setTimeout(() => {
            fn();
        }, 500);
    };
}
let callback401 = debounce401(() => {
    Vue.prototype.$message.error("登录失效");
    localStorage.clear();
    router.push({ path: "/login" });
});

//  请求拦截器
service.interceptors.request.use(
    (config) => {
        config.headers["Content-Type"] = "application/json;charset=UTF-8";
        //config.headers["powerStationCode"] = "HNTT";
        let token = localStorage.getItem("token") || null;
        if (token) {
            // 如果token不为null，否则传token给后台
            config.headers["Authorization"] = token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        // removePedding(response.config)
        if (response.config.url.endsWith("getDesign")) return response;
        if (response.headers.authorization) {
            localStorage.setItem("token", response.headers.authorization);
        }
        const res = response.data;
        //届时根据后端返回success或者code值判断
        if (res.code === 200) {
            return res;
        } else if (res.code === 401 || res.code === 402) {
            callback401();
        } else {
            Vue.prototype.$message.error(res.msg);
            return Promise.reject(res);
        }
    },
    (error) => {
        if (error.message.includes("timeout of")) {
            Vue.prototype.$message.error("响应超时");
        } else {
            if (error.response.status === 401) {
                callback401();
            }
        }
        //响应错误
        return Promise.reject(error);
    }
);

Vue.prototype.$axios = service;
export default service;
